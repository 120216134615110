import type { RoutesData } from '@/router/routerInitPlugin'
import OgImg from '@/assets/images/global/og_image.jpeg'
import CookingWarsOgImg from '@/assets/images/pages/games/cookingWars/CW_1200-min.jpg'
import FwOgImg from '@/assets/images/pages/games/fw/FW_1200-min.jpeg'
import GangOgImg from '@/assets/images/pages/games/gang/TG_1200-min.jpeg'
import HlOgImg from '@/assets/images/pages/games/hl/hl_1200-min.jpg'
import MafiaOgImg from '@/assets/images/pages/games/mafia/MR_1200-min.jpg'
import QlOgImg from '@/assets/images/pages/games/ql/QL_1200-min.jpeg'
import SnrOgImg from '@/assets/images/pages/games/snr/SnR_1200-min.jpeg'
import SoccerOgImg from '@/assets/images/pages/games/soccer/SC_1200-min.jpg'
import HomePage from '@/pages/index/HomePage.vue'

export default (): RoutesData[] => {
  return [
    {
      path: '/',
      name: 'home-page',
      component: HomePage,
      meta: {
        basicSeo: {
          title: 'Gamesture',
          description: 'GAMESTURE COMPANY. PROUD CREATORS OF QUESTLAND, HERO LEGACY, THE GANG, SLASH & ROLL AND FABLE WARS.' + 'A TEAM OF PEOPLE WHO TRULY LOVE GAMING, AND WANT THEIR WORK TO REFLECT THAT',
          image: OgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy-page',
      component: () => import('@/pages/info/PrivacyPolicyPage.vue'),
      meta: {
        basicSeo: {
          title: 'Privacy policy - Gamesture store',
          description: 'Privacy policy',
          image: OgImg,
        },
        bodyClass: 'hide-cookies-bar',
      },
    },
    {
      path: '/terms-of-service',
      name: 'terms-of-service-page',
      component: () => import('@/pages/info/TermsOfServicePage.vue'),
      meta: {
        basicSeo: {
          title: 'Terms of service - Gamesture store',
          description: 'Terms of service ',
          image: OgImg,
        },
        bodyClass: 'hide-cookies-bar',
      },
    },
    {
      path: '/games/questland',
      name: 'games-questland',
      component: () => import('@/pages/games/QuestlandPage.vue'),
      meta: {
        basicSeo: {
          title: 'Questland',
          description:
            'Questland game of Gamesture company. HIGHLY ADDICTIVE FANTASY GAME!' +
            ' A FIRST-PERSON PERSPECTIVE RPG WITH ELEMENTS OF A DUNGEON CRAWLER. AN IMMERSIVE MOBILE EXPERIENCE THAT CARRIES THE LEGACY OF GAMES WE PLAYED AS KIDS. Collect exquisite gear, customize your hero with hundreds of looks, and upgrade your way to the top with powerful Orbs and enhancements. Fight epic monsters, duel other players online, create Guilds, and more in this incredibly dynamic turn-based action game with CCG elements!',
          image: QlOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/games/hero-legacy',
      name: 'games-hero-legacy',
      component: () => import('@/pages/games/HeroLegacyPage.vue'),
      meta: {
        basicSeo: {
          title: 'Hero Legacy',
          description:
            'Hero Legacy game of Gamesture company.' +
            ' AN EPIC ADVENTURE IN THE OPEN WORLD OF ESTARIA.' +
            ' A BRILLIANT MASHUP BETWEEN KINGDOM BUILDER AND STRATEGY BATTLER IN REAL TIME.' +
            ' Hero! Thank Gaia, you returned! The world you remember is gone. Estaria is being consumed by the forces of Corruption... But even in the deepest despair, there is hope. You are the royal child, an heir to the throne, and the light of the future. Reclaim your Legacy!',
          image: HlOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/games/slash-and-roll',
      name: 'games-slash-and-roll',
      component: () => import('@/pages/games/SlashAndRollPage.vue'),
      meta: {
        basicSeo: {
          title: 'Slash&Roll',
          description:
            'Slash&Roll game of Gamesture company.' +
            ' A BRAND NEW TAKE ON AN MMORPG! AN EXCITING MULTIPLAYER SKIRMISH FOR DOMINATION IN THE RANKING. AN IMMERSIVE SOCIAL' +
            ' EXPERIENCE AND REAL-TIME BATTLES.' +
            ' Create or join guilds, devise tactics, and convince your enemies to switch sides! Take advantage of unique character customization options, and get weapons and mounts to boost your power! Gather your team and crush the enemies together in dynamic, challenging battles!',
          image: SnrOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/games/the-gang',
      name: 'games-the-gang',
      component: () => import('@/pages/games/TheGangPage.vue'),
      meta: {
        basicSeo: {
          title: 'The Gang',
          description:
            'The Gang game of Gamesture company.' +
            ' GET READY FOR THE EPIC GANG GAMES!' +
            ' BECOME THE LEADER OF A MOB, CREATE YOUR OWN CRIME STYLE, RECRUIT YOUR MOB MEMBERS, AND FIGHT WITH OTHER GANGS LED BY REAL PLAYERS FROM ALL OVER THE WORLD.' +
            ' The battle over the neighborhoods has begun! Take over the territories to get some points in gang battles and rule the entire street. Defend it from rivals and raid other turfs. Fight side by side with your friends in non-stop action.',
          image: GangOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/games/fable-wars',
      name: 'games-fable-wars',
      component: () => import('@/pages/games/FwPage.vue'),
      meta: {
        basicSeo: {
          title: 'Fable Wars',
          description:
            'Fable wars game of Gamesture company.' +
            ' AN IMMERSIVE CARD BATTLER!' +
            ' COLLECTIBLE CARD BATTLER FEATURING HEROES OF FABLES AND LEGENDS. A UNIQUE GAME DRAWING ON THE JOYS OF DECK BUILDING' +
            ' AND AN ENCHANTED RPG EXPERIENCE.' +
            ' Traverse the enchanted forest in Fable Wars unique take on a card puzzle RPG game! Put together a team of fable' +
            ' heroes and save the fantasy world by defeating the enemies in a series of thrilling puzzle quests. Build your card' +
            ' collection and assemble a team of legendary heroes!',
          image: FwOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/games/mafia-rivals',
      name: 'mafia-rivals',
      component: () => import('@/pages/games/MafiaRivalsPage.vue'),
      meta: {
        basicSeo: {
          title: 'Mafia Rivals',
          description:
              'Mafia Rivals game of Gamesture company. ' +
              'BUILD THE GRAND MAFIA FAMILY. MAKE YOUR OWN RULES IN GANGSTER GAMES. TAKE OVER THE MAFIA CITY. TIME TO STEP IN AND LET THE GRAND MAFIA GAME BEGIN, MAFIOSO! ' +
              'If you need the thrill of fight and revenge, you have found your place, boss. ' +
              'Mafia Rivals bring the thrill of the streets to your screen. ' +
              'Join the grand mafia games, and let them take you right into the center of real gangster crimes. ' +
              'The crime city awaits and it\'s your mission to make it your own!',
          image: MafiaOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/games/soccer-clash',
      name: 'soccer-clash',
      component: () => import('@/pages/games/SoccerClashPage.vue'),
      meta: {
        basicSeo: {
          title: 'Soccer Clash',
          description:
              'Soccer Clash game of Gamesture company. ' +
              'BECOME A SOCCER STAR AND LEAD YOUR TEAM TO GLORY IN FOOTBALL GAMES! ' +
              'Soccer Clash combines fast-paced football, a soccer manager career, and real-time multiplayer action to give you the total football experience. ' +
              'Build your team, customise your players, and compete in intense daily matches that test your skills. ' +
              'Whether you’re a football manager or a future soccer superstar, this football simulator has everything you need to succeed as the new score hero!',
          image: SoccerOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/games/cooking-wars',
      name: 'cooking-wars',
      component: () => import('@/pages/games/CookingWarsPage.vue'),
      meta: {
        basicSeo: {
          title: 'Cooking Wars',
          description:
              'BECOME THE MASTER CHEF. ' +
              'BUILD YOUR OWN RESTAURANT EMPIRE. CHALLENGE RIVALS IN EPIC COOKING BATTLES! ' +
              'It’s time to sharpen your knives and dominate the kitchen! ' +
              'If you crave the thrill of competition and the taste of victory, Cooking Wars is your battleground. ' +
              'Compete against top chefs, perfect your recipes, and rise to culinary fame. The kitchen arena awaits—will you be the ultimate cooking champion?',
          image: CookingWarsOgImg,
        },
        bodyClass: '',
      },
    },
    {
      path: '/jobs',
      name: 'jobs-page',
      component: () => import('@/pages/jobs/JobsPage.vue'),
      meta: {
        basicSeo: {
          title: 'Jobs',
          description: 'Gamesture job offers.',
          image: OgImg,
        },
        bodyClass: '',
      },
    },
    // {
    //   path: '/test_dev_page/:id',
    //   name: 'test-page',
    //   component: () => import('@/pages/dev/TestPage.vue'),
    //   meta: {
    //     basicSeo: {
    //       title: '{titlePage} {id} - page',
    //       description: 'description {titlePage} {id} - page',
    //       image: '',
    //       defaultVariables: {
    //         titlePage: 'Test Page'
    //       }
    //     },
    //     bodyClass: 'bg-cyan-200'
    //   },
    //   ssg: [
    //     {
    //       path: '/test_dev_page/{id}',
    //       name: 'test-page-{id}',
    //       image: 'imagetest1',
    //       defaultVariables: {
    //         id: 'test1'
    //       }
    //     }
    //   ]
    // }
  ]
}
