<script setup lang="ts">
import { vIntersectionObserver } from '@vueuse/components'
import { useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

function sectionObserver(hash: string, prevHash: string) {
  return ([observerData]: IntersectionObserverEntry[]) => {
    if (!import.meta.env.SSR) {
      const { isIntersecting } = observerData
      if (isIntersecting) {
        router.push({ name: 'home-page', hash })
      } else if (route.hash === hash && prevHash) {
        router.push({ name: 'home-page', hash: prevHash })
      }
    }
  }
}
</script>

<template>
  <section-home-index-page-component v-intersection-observer="[sectionObserver('#home', ''), { threshold: 0.25, rootMargin: '0% 0% -50% 0%' }]" />
  <section-games-slider-component v-intersection-observer="[sectionObserver('#games', '#home'), { threshold: 0.25, rootMargin: '0% 0% -50% 0%' }]" />
  <section-about-index-page-component v-intersection-observer="[sectionObserver('#about', '#games'), { threshold: 0.25, rootMargin: '0% 0% -50% 0%' }]" />
  <section-benefits-index-page-component v-intersection-observer="[sectionObserver('#benefits', '#about'), { threshold: 0.25, rootMargin: '0% 0% -50% 0%' }]" />
<!--  <section-contact-index-page-component v-intersection-observer="[sectionObserver('#contact', '#benefits'), { threshold: 0.25, rootMargin: '0% 0% -20% 0%' }]" /> -->
</template>

<style scoped lang="postcss"></style>
